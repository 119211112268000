import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { defaultPageTransition } from '../utilities/animationHelpers'

const BookLink = ({ book }) => {
  return (
    <div
      className='flex justify-center my-6 mx-4'
      style={{
        flex: '0 1 30%',
      }}
    >
      <TransitionLink
        {...defaultPageTransition}
        to={`${book.slug}cover`}
        className='inline w-7/12'
        style={{ minWidth: '175px' }}
      >
        <img className='mb-4' src={book.image}></img>
        <span className='text-2xl underline'>{book.title}</span>
      </TransitionLink>
    </div>
  )
}

export default BookLink
