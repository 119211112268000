/*
https://transitionlink.tylerbarnes.ca/docs/transitionstate/ for page transition docs
*/

import React from 'react'
import { graphql } from 'gatsby'
import '../styles/index.css'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import BookLink from '../components/BookLink'
import Undraw from '../assets/svgs/weirdundraw.svg'

import { TransitionState } from 'gatsby-plugin-transition-link'
import { defaultTransition } from '../utilities/animationHelpers'

import SEO from '../components/seo'

const variants = {
  enter: {
    opacity: 1,
    transition: {
      ...defaultTransition,
      staggerChildren: 0.2
    }
  },
  exit: {
    opacity: 0,
    transition: defaultTransition
  },
  hidden: {
    opacity: 0
  }
}

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 60
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: defaultTransition
  }
}

const Index = ({ data }) => {
  const { scrollY, scrollYProgress } = useViewportScroll()
  const svgPosition = useTransform(scrollY, value => 40 + value / 30)

  let books = data.allMarkdownRemark.edges.map(({ node }) => ({
    title: node.frontmatter.title,
    id: node.id,
    slug: node.fields.slug,
    image: node.frontmatter.cover,
  }))

  // let debugBook = {
  //   title: 'hello',
  //   slug: '/hello/',
  //   image: books[0].image,
  // }

  // for (let i = 0; i < 10; i++) {
  //   books.push({ ...debugBook, id: Math.floor(Math.random() * 100000) })
  // }

  return (
    <div>
      <SEO title='Ken Sparling' />
      <TransitionState>
        {({ transitionStatus, entry }) => (
          <motion.div
            variants={variants}
            initial='hidden'
            animate={['entering', 'entered', 'POP'].includes(transitionStatus) ? 'enter' : 'exit'}
          >
            <motion.div 
              className='fixed md:left-0 md:ml-8 md:mt-0 mt-40 left-1/2 transform md:translate-x-0 -translate-x-1/2 left-50 xl:w-7/12 lg:w-6/12 md:w-5/12 w-big-image'
              style={{ zIndex: -1, top: svgPosition }}
            >
              <Undraw />
            </motion.div>
            <motion.h1 
            variants={itemVariants}
            className='leading-none md:text-right text-center text-gray-900 md:mr-32 mt-10 md:text-heading text-7xl'>
              Ken
              <br />
              Sparling
            </motion.h1>
            <motion.section
              variants={itemVariants}
              className='rounded-lg w-10/12 mx-auto my-10 py-6 flex flex-wrap justify-center'
              style={{
                backgroundColor: 'rgba(190, 227, 248, 0.8)',
              }}
            >
              {books.map(book => (
                <BookLink key={book.id} book={book} />
              ))}
            </motion.section>
          </motion.div>
        )}
      </TransitionState>
    </div>
  )
}

export default Index

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            cover
          }
        }
      }
    }
  }
`
